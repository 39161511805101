<template>
  <Layout>
    <!-- Start Accordion Area -->
    <div class="rn-accordion-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <SectionTitle
                class="mb--30"
                text-align="center"
                subtitle="安连德语"
                title="安连圣诞文化节"
                description="每年的12月25日，是基督教徒纪念耶稣诞生的日子，称为圣诞节。

从12月24日于翌年1月6日为圣诞节节期。节日期间，各国基督教徒都举行隆重的纪念仪式。圣诞节本来是基督教徒的节日，由于人们格外重视，它便成为一个全民性的节日，国家一年中最盛大的节日，可以和新年相提并论，类似我国过春节。

每年的圣诞节我们都会举办活动，师生一起Happy，让学生们能深深感受到西方圣诞节的节日气氛,快来加入我们吧！ "
                data-aos="fade-up"
                data-aos-delay="60"
            />
          </div>
        </div>
        <br>
        <br>




        <!--        开始-->

        <div class="rwt-gallery-area rn-section-gapBottom">
          <div class="container">
            <Gallery :gallery-data="galleryData" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
          </div>
        </div>

        <!--        结束-->






      </div>
    </div>
    <!-- End Accordion Area -->

    <Separator/>


  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Separator from '../components/elements/separator/Separator'
import Gallery from "@/components/elements/gallery/Gallery";

export default {
  name: 'AccordionPage',
  components: { Layout, SectionTitle, Separator,Gallery},
  data() {
    return {
      galleryData: [
        {
          id: 1,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s1.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s1.png',
          type: 'image'
        },
        {
          id: 2,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s2.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s2.png',
          type: 'image'
        },
        {
          id: 3,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s3.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s3.png',
          type: 'image'
        },
        {
          id: 4,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s4.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s4.png',
          type: 'image'
        },
        {
          id: 5,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s5.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s5.png',
          type: 'image'
        },
        {
          id: 6,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s6.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s6.png',
          type: 'image'
        },
        {
          id: 7,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s7.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s7.png',
          type: 'image'
        },
        {
          id: 8,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s8.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s8.png',
          type: 'image'
        },
        {
          id: 9,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s9.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/christmasday/%E5%9C%A3%E8%AF%9E%E8%8A%82/s9.png',
          type: 'image'
        }

      ]
    }
  },
}
</script>
