<template>
    <Layout>
        <div class="rn-blog-details-area">
            <div class="post-page-banner rn-section-gapTop">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content text-center">
                                <div class="page-title">
                                    <h1 class="theme-gradient" v-html="post.title"/>
                                </div>
                                <ul class="rn-meta-list">
                                    <li>
                                        <Icon name="user" class="size-20"/> <router-link to="#">安连小编</router-link>
                                    </li>
                                    <li>
                                        <Icon name="calendar" class="size-20"/> {{ post.publishedAt }}
                                    </li>
                                </ul>
                                <div class="thumbnail alignwide mt--60">
                                    <img class="w-100 radius"
                                         :src="post.img"
                                         :alt="post.title">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content">
                                <p v-html="post.body"/>
                                </div>

                                <!-- Start Contact Form Area  -->

                                <!-- End Contact Form Area  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Icon from '../components/icon/Icon'
    import BlogPostMixin from '../mixins/BlogPostMixin'

    export default {
        name: 'BlogDetailsPage',
        components: {Icon, Layout, Breadcrumb},
        mixins: [BlogPostMixin],
        data() {
            return {
                id: this.$route.params.id,
                post: {
                    publishedAt: "2022/10/19",
                    title: '安连德语优惠政策',
                    img: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/%E5%AE%89%282%29.png',
                    body: "<h3>安连德语优惠政策:</h3><p>a.坚持有礼:凡一次报名安连80O学时的学员，均获赠《新求精德语初级ⅠⅡ》《新求精德语中级ⅠⅡ》教材4套，包含词汇手册和测试题;</p><p>b.进步有礼:凡参加过安连课程的学生，如果再报名新的课程，将获赠相应等级的教材;</p><p>c.参与有礼:凡报名新生，</p><p> d.可以受邀，参加'安连迎新派对'</p><p>e.课程结束时可以受邀，参加'欢庆自助派对';</p><p>f.每年可以受邀，参加'安连德国啤酒节';</p><p>g.每年可以受邀，参加'安连圣诞欢庆派对';</p><p>h.每年复活节，所有在读学员都有'惊喜礼品'赠送;</p>"
                    }
            }
        },
        methods: {
            getPost(postId) {
                this.post = this.posts.find(item => item.id == postId);
            }
        },
        mounted() {
            this.getPost(this.id);
        }


    }
</script>
