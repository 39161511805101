<template>
    <Layout>
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"
                            subtitle="Contact Form"
                            title="联系我们"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="headphones" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">联系电话</h4>
                                            <p><a href="tel:029-89322004">座机：029-89322004 </a></p>
                                            <p><a href="tel:18092187889">手机：18092187889</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">邮箱</h4>
                                            <p><a href="mailto:admin@gmail.com">281917874@qq.com</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="map-pin" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">位置</h4>
                                            <p>西安市长安中路小寨十字，华旗国际广场B座815室（地铁小寨站C出口） <br /> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

<!--                <div class="row mt&#45;&#45;40 row&#45;&#45;15">-->
<!--                    <div class="col-lg-7">-->
<!--                        <ContactForm/>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>




<!-- 复制的一份 -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"

                            title="关注我们"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">

                                        <div class="inner">
<!--                                            <p><a href="tel:029-89322004">029-89322004 </a></p>-->
                                            <h4 class="title">微信公众号</h4>
                                          <p><img src="../assets/images/contact/weixin.png"></p>


                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
<!--                                        <div class="icon">
                                            <Icon name="mail" :size="40"/>
                                        </div>-->
                                        <div class="inner">
                                            <h4 class="title">抖音</h4>
<!--                                            <p><a href="mailto:admin@gmail.com">admin@gmail.com</a></p>-->
                                            <p><img src="../assets/images/contact/weixin.png"></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
<!--                                        <div class="icon">-->
<!--                                            <Icon name="map-pin" :size="40"/>-->
<!--                                        </div>-->
                                        <div class="inner">
                                            <h4 class="title">微博</h4>
                                          <p><img src="../assets/images/contact/weixin.png"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
        <!-- End Contact Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Icon from "../components/icon/Icon";
    import GoogleMap from "../components/elements/contact/GoogleMap";
    import ContactForm from "../components/elements/contact/ContactForm";

    export default {
        name: 'ContactPage',
        components: {ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout}
    }
</script>
