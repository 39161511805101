<template>
    <div class="copyright-area copyright-style-one variation-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-7 col-sm-12 col-12">
                    <div class="copyright-left">
                        <ul class="ft-menu link-hover">
                            <li><router-link to="#">隐私政策</router-link></li>
                            <li><router-link to="#">投诉与建议</router-link></li>
                            <li><router-link to="/contact">联系我们</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 mt_sm--20">
                    <div class="copyright-center text-center">
                        <div class="copyright-center text-center ">
                        <a class="copyright-text" href="https://beian.miit.gov.cn">  {{icp}}</a>
                    </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                    <div class="copyright-right text-center text-lg-end">
                        <p class="copyright-text">
                             © {{ new Date().getFullYear() }} 西安安连德语有限责任公司版权所有.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'CopyrightTwo',
        components: {Icon},
        data() {
            return {
                icp: "陕ICP备2020012945号"
            }
        },
    }
</script>