import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: '安连德语',
        component: () => import("../views/home-pages/BusinessConsulting"),
        meta: {
            title: '安连德语',
        },
    },

    {
        path: '/about',
        name: 'About',
        component: () => import("../views/About"),
        meta: {
            title: '关于我们',
        },
    },
    {
        path: '/teacher',
        name: 'Teacher',
        component: () => import("../views/Teacher"),
        meta: {
            title: '精英师资',
        },
    },
    {
        path: '/christmasday',
        name: 'ChristmasDay',
        component: () => import("../views/ChristmasDay"),
        meta: {
            title: '安连圣诞节',
        },
    },
    {
        path: '/beerfestiva',
        name: 'BeerFestiva',
        component: () => import("../views/BeerFestiva"),
        meta: {
            title: '安连啤酒节',
        },
    },
    {
        path: '/easterday',
        name: 'EasterDay',
        component: () => import("../views/EasterDay"),
        meta: {
            title: '安连复活节',
        },
    },

    {
        path: '/enrol',
        name: 'enrol',
        component: () => import("../views/EnrolStudent"),
        meta: {
            title: '安连招生',
        },
    },
    {
        path: '/studentQuestion',
        name: 'studentQuestion',
        component: () => import("../views/StudentQuestion"),
        meta: {
            title: '学生问题',
        },
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import("../views/Policy"),
        meta: {
            title: '优惠政策',
        },
    },
    {
        path: '/highStudent',
        name: 'highStudent',
        component: () => import("../views/HignStudent"),
        meta: {
            title: '德语高分学员',
        },
    },



    {
        path: '/contact',
        name: 'Contact',
        component: () => import("../views/ContactPage"),
        meta: {
            title: '联系我们',
        },
    },

    {
        path: '/error',
        name: 'Error',
        component: () => import("../views/404"),
        meta: {
            title: '404',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
