<template>
    <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
        <div class="accordion" :id="id">
            <div class="accordion-item card" v-for="(content, index) in accordionContent">
                <h2 class="accordion-header card-header" :id="`heading${content.id}`">
                    <button class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#collapse${content.id}`"
                            :aria-expanded="index === activeCollapse ? 'true' : 'false'"
                            :aria-controls="`collapse${content.id}`"
                            @click="activeCollapse = index">
                        {{ content.title }}
                        <i class="collapse-icon"/>
                    </button>
                </h2>
                <div :id="`collapse${content.id}`"
                     class="accordion-collapse collapse"
                     :class="{'show': index === 0}"
                     :aria-labelledby="`heading${content.id}`"
                     :data-bs-parent="`#${id}`">
                    <div class="accordion-body card-body">
                        {{ content.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Accordion',
        props: {
            id: {
                type: String,
                default: 'accordionExample'
            },
            accordionStyleClass: {
                type: String
            },
            accordionContent: {
                type: Array,
                default: function () {
                    return [
                        {
                            id: 'One',
                            title: '1. 安连德语是什么样的机构?',
                            description: '安连德语（西安》是由国家外专局培训中心西安外语培训基地引进的专业的德语培训体系，已经在西安培训了14年，目前是西安最大的德语培训，也是德国留学首选的德语培训机构.'
                        },
                        {
                            id: 'Two',
                            title: '2. 为什么以前没听说过安连德语?',
                            description: '安连德语，在西安已经有很久的历史。之前的14年一直是和西安知名的高校合作，承担着校内德国项目德语培训。是德国大学和留德审核部认可合作的培训学校,我们的服务对象有西安电子科技大学，长安大学，西安石油大学，西安邮电大学，陕西科技大学,西北工业大学等。'
                        },
                        {
                            id: 'Three',
                            title: '3. 安连德语和德国国际高等教育协会的关系?',
                            description: '安连德语(西安)是德国国际高等教育协会(AIAEG）在陕西地区指定的合作伙伴和认可的培训机构'
                        },
                        {
                            id: 'Four',
                            title: '4. 安连的优势是什么呢?',
                            description: '可为学生开具德国驻华大使馆和德国大学认可的德语课时证明;以欧洲语言共同参照标准（GER）作为教学标准，遵循安连德语（Allianz Deutsch)全套德语教学体系，接受安连德语(Allianz Deutsch）全面教学质量评估和监控;德国教师与中国教师协同授课，通过分级考核将学员安排到合适的班级;有从零基础到能入读德国大学的各个级别德语课程教学;采用德国本土的德语交际法为主的教学方法，例如:角色扮演、课堂互动、学生报告会,模拟实景训练等;使用高质量的德语教材，带给中国学生全新的德语学习感受，在听、说、读、写各方面对学员进行专项训练;小班授课，按照德国大学语言教学要求，每班人数不超过25人。'
                        },
                        {
                            id: 'Four',
                            title: '5. 采用什么样的授课方式?',
                            description: '中外教协同授课，初级班中教比例60%-70%，中级班外教比例40%-60%。初级中教为主，外教为辅，主攻德语语法，建立扎实的德语语法基础!中级中外教双管齐下，复习语法，词汇,强化口语，听力!'
                        },
                        {
                            id: 'Four',
                            title: '6. 安连的优势是什么呢安连德语的教学质量怎么样?',
                            description: '可为学生开具德国驻华大使馆和德国大学认可的德语课时证明;以欧洲语言共同参照标准（GER）作为教学标准，遵循安连德语（Allianz Deutsch)全套德语教学体系，接受安连德语(Allianz Deutsch）全面教学质量评估和监控;德国教师与中国教师协同授课，通过分级考核将学员安排到合适的班级;有从零基础到能入读德国大学的各个级别德语课程教学;采用德国本土的德语交际法为主的教学方法，例如:角色扮演、课堂互动、学生报告会,模拟实景训练等;使用高质量的德语教材，带给中国学生全新的德语学习感受，在听、说、读、写各方面对学员进行专项训练;小班授课，按照德国大学语言教学要求，每班人数不超过25人。'
                        }
                    ]
                }
            }
        },
        data() {
            return {
                activeCollapse: 0
            }
        }
    }
</script>
