<template>
  <Layout>
    <div class="rn-blog-details-area">
      <div class="post-page-banner rn-section-gapTop">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="content text-center">
                <div class="page-title">
                  <h1 class="theme-gradient" v-html="post.title"/>
                </div>
                <ul class="rn-meta-list">
                  <li>
                    <Icon name="user" class="size-20"/> <router-link to="#">CodeCow</router-link>
                  </li>
                  <li>
                    <Icon name="calendar" class="size-20"/> {{ post.publishedAt }}
                  </li>
                </ul>
                <div class="thumbnail alignwide mt--60">
                  <img class="w-100 radius"
<!--                       :src="post.img"-->
<!--                       :alt="post.title">-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-details-content pt--60 rn-section-gapBottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="content">
                <p v-html="post.body"/>
              </div>

              <!-- Start Contact Form Area  -->

              <!-- End Contact Form Area  -->
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Icon from '../components/icon/Icon'
import BlogPostMixin from '../mixins/BlogPostMixin'

export default {
  name: 'BlogDetailsPage',
  components: {Icon, Layout, Breadcrumb},
  mixins: [BlogPostMixin],
  data() {
    return {
      id: this.$route.params.id,
      post: {
        publishedAt: "2023/03/15",
        title: '春季德语班招生开始啦！',
        img: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/QQ%E5%9B%BE%E7%89%8720230315190139.png',
        body: "<p>寒假结束，你准备好迎接春天了吗？在最美的季节，最大的热情，以最好的状态，全身心投入德语学习之中，争取拿下更多的德语芝士！这个春季，在安连德语开启你的德语学习之旅~</p><h3 style='text-align: center'>安连德语2023春季课程:</h3>  <img src='https://anliandy.oss-cn-hangzhou.aliyuncs.com/zs1.png'><img src='https://anliandy.oss-cn-hangzhou.aliyuncs.com/zs2.png'><h5>备注：</h5>\n" +
            "\n" +
            "<p>课程满6人开课，不足6人，我们保留更改、调整课程起止日期的权利。\n</p>" +

            "\n" +
            "<h5>报名时间：\n</h5>" +
            "\n" +
            "<p>即日起，报满为止。\n</p>" +
            "\n" +

            "<h5>报名咨询专线：\n</h5>" +
            "\n" +
            "<p>18092187889（同微信）</p></h5>" +
            "<div style='text-align: center'><img src='https://anliandy.oss-cn-hangzhou.aliyuncs.com/QQ%E5%9B%BE%E7%89%8720230315185555.png' ></div>"
      }
    }
  },
  methods: {
    getPost(postId) {
      this.post = this.posts.find(item => item.id == postId);
    }
  },
  mounted() {
    this.getPost(this.id);
  }


}
</script>
