<template>
    <Layout header-class="header-left-align" :footer-style="1">
        <!-- Start Slider Area -->
      <!-- Start Slider Area -->
      <div class="slider-area slider-style-1 height-650 bg_image"
           data-black-overlay="3"
           :style="aboutBgimage">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="inner pt--80 text-center">
                <div>
                  <h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                    <span class="theme-gradient">Allianz Deutsch </span>
                  </h3>
                </div>
                <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                  西安安连德语培训中心
                </h1>
                <p class="description" data-aos="fade-up" data-aos-delay="130" style="color: #fff700">
                  安连德语  只为德语<br>
                  安连专注于德语培训   有幸与你同行
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Slider Area -->
        <!-- End Slider Area -->


        <!-- Start About Area -->
      <!-- Start Elements Area -->
      <div class="rwt-tab-area rn-section-gap">
        <div class="container">
          <div class="row mb--40">
            <div class="col-lg-12">
              <SectionTitle
                  text-align="center"
                  subtitle="Allianz Deutsch"
                  title="学德语来安连优势"
                  description="目前已经成为西安著名的德语专业培训机构，安连德语的德语培训以及德福成绩在业内受人瞩目，受到了同学们极大肯定"
                  data-aos="fade-up"
              />
            </div>
          </div>
          <NewTab :tab-data="tabData"/>
        </div>
      </div>
      <!-- End Elements Area -->
        <!-- End About Area -->

        <!-- Start Service Area -->
        <!-- 新加的2022/10/19 -->
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            subtitle="西安安连德语"
                            title="花样授课模式让你快乐学德语"
                            description="安连专注于德语培训 <br /> 有幸与你同行"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>

        <div class="rn-service-area rn-section-gapBottom pt--100">
            <div class="container">
                <ServiceOne
                    service-style="service__style--1 icon-circle-style"
                    text-align="center"
                    icon-size="39"
                />
            </div>
        </div>
        <!-- End Service Area -->

        <Separator/>



        <Separator/>



      <!--      2022/11/18开始-->

      <div class="rwt-gallery-area rn-section-gapBottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 mb--20">
              <SectionTitle
                  text-align="center"
                  subtitle="课堂精彩"
                  title="精彩的课堂生活"
              />
            </div>
          </div>
          <Gallery :gallery-data="galleryData" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
        </div>
      </div>
      <!--      2022/11/18结束-->


        <!-- Start Video Area -->
        <div class="rwt-video-area rn-section-gapBottom">
            <div class="container">
              <div class="row">
              </div>
                <Video :video-data="videoData" :video-style="1" column="col-lg-12" data-aos="fade-up"/>
            </div>
        </div>
        <!-- End Video Area -->

        <Separator/>



        <Separator/>


        <!-- Start Elements Area -->
        <div class="rwt-callto-action-area rn-section-gapBottom">
            <div class="wrapper">
                <CallToAction
                    :style-type="5"
                    title="准备好学习德语or留学了吗?"
                    subtitle="赶快联系我们吧"
                    btn-text="在线联系"
                />
            </div>
        </div>
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import Separator from '../../components/elements/separator/Separator'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import ServiceOne from '../../components/elements/service/ServiceOne'
    import Portfolio from '../../components/elements/portfolio/Portfolio'
    import Timeline from '../../components/elements/timeline/Timeline'
    import Video from '../../components/elements/video/Video'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import BlogPostMixin from '../../mixins/BlogPostMixin'
    import BlogPost from '../../components/blog/BlogPost'
    import CallToAction from '../../components/elements/callToAction/CallToAction'
    import Gallery from "@/components/elements/gallery/Gallery";
    import NewTab from "@/components/elements/tab/NewTab";

    export default {
        name: 'BusinessConsulting',
        components: {
          NewTab,
            ServiceOne,
            CallToAction,
            BlogPost,
            Testimonial,
            Video,
            Timeline,
            Portfolio,
            SectionTitle,
            Separator,
            Icon,
            Layout,
            Gallery
        },
        mixins: [BlogPostMixin],

      computed:{
        firstBgImage(){
          return "background:url("+this.firstBgImag+")"+"center center /cover no-repeat"
        },
        aboutBgimage(){
          return "background:url("+this.aboutBgimag+")"+"center center /cover no-repeat"
        }
      },
        data() {
            return {
              tabData: {
                tabImage: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/%E5%AE%89.png',
                tabContent: [
                  {
                    id: 1,
                    menu: '精英教学团队',
                    content: `<p>目前安连德语培训学校共有专、兼职德语老师（中教及外教）20人左右。教师团队由德语专业教授，硕博，海归名师组成。更有专职外教，德国本土教师，HSK汉语6级，纯正德式发音，7年教学经验。</p><p>教师团队实力授课，数年教学经验，专注德语各类考试备考，如欧标等级，德福，DSH，德适课程，高考德语，考研二外等系列考试。学校拥有强大的德福教师团队，和全面、系统、详尽的德福考试备考资料，每年寒暑假全国慕名而来的学生络绎不绝。</p>`,
                  },
                  {
                    id: 2,
                    menu: '更优质的教学服务',
                    content: `<p>安连德语严格把控教学质量，每一期课程都会定期进行单词测试与写作练习，并组织期中和期末考试，督促学员养成良好的学习习惯，使德语学习效果达到最佳。另外，所有的工作人员均是德语专业的，让同学们可以在课堂之外也能随时随地练习德语.</p><p>等级班线上线下同步教学，所有课程均有录播回放，可无限次观看回放视频，随时随地复习巩固。定制小班学生可根据自身情况灵活选择上课方式。

学校位于小寨，交通便利。各个教室均配有投影、空调、暖气、饮水机、咖啡和小零食等。</p>`,
                  },
                  {
                    id: 3,
                    menu: '团审项目和个审项目',
                    content: `<p>校际合作院校学生可参加团审项目，可以用德国高校的面试代替APS面谈的环节。非合作院校的同学，可选择个审，安连德语可提供完整的留学规划，APS面试辅导，择校方案制定等一站式的留学解决方案。</p><p>Allianz Deutsch </p>`,
                  }
                ]
              },
              aboutBgimag: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2018-04-28%2F5ae3d890c8953.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671717722&t=5a65aeec79f9760891948327f0efa433",
              firstBgImag:"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.bizhi360.com%2Fbbpic%2F75%2F575.jpg&refer=http%3A%2F%2Fpic.bizhi360.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671689793&t=38901859799e9c5d50a2a772f8798296",
                bgimage: '',
              galleryData: [
                {
                  id: 1,
                  thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/ketang/1.png',
                  src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/ketang/1.png',
                  type: 'image'
                },
                {
                  id: 2,
                  thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/ketang/2.png',
                  src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/ketang/2.png',
                  type: 'image'
                },
                {
                  id: 3,
                  thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/ketang/3.png',
                  src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/ketang/3.png',
                  type: 'image'
                },

              ],
                timelineData: [
                    {
                        id: '1',
                        date: 'Step-1',
                        title: 'Your working process you can present.',
                        description: 'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
                        image: 'timeline-01',
                        workingStep: [
                            {
                                stepTitle: 'Gain your works',
                                stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                            },
                            {
                                stepTitle: 'Working process',
                                stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                            },
                        ]
                    },
                    {
                        id: '2',
                        date: 'Step-2',
                        title: 'Our compnay working process to present.',
                        description: 'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
                        image: 'timeline-02',
                        workingStep: [
                            {
                                stepTitle: 'Follow this process',
                                stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                            },
                            {
                                stepTitle: 'Working experience',
                                stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                            },
                        ]
                    },
                ],
                videoData: [
                    {
                        thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/1290620.png',
                        src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/2018.10.14%E5%AE%89%E8%BF%9E%E5%BE%B7%E8%AF%AD%E5%8D%81%E5%91%A8%E5%B9%B4%E5%BA%86%E5%85%B8%E8%8A%B1%E7%B5%AE%E7%89%88.mp4',
                        title: `西安安连德语 <br/> 专注于德语培训，有幸与你同行。`
                    }
                ],
                testimonialData5: [
                    {
                        form: 'Poland',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'John Due',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-01'
                    },
                    {
                        form: 'Germany',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'John Due',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-02'
                    },
                    {
                        form: 'USA',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'Janen',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-03'
                    },
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ]
            }
        }
    }
</script>
