<template>
  <Layout>
    <!-- Start Accordion Area -->
    <div class="rn-accordion-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <SectionTitle
                class="mb--30"
                text-align="center"
                subtitle="安连德语"
                title="德国啤酒节"
                description=""
                data-aos="fade-up"
                data-aos-delay="60"
            />
          </div>
        </div>
        <br>
        <br>




<!--        开始-->

        <div class="rwt-gallery-area rn-section-gapBottom">
          <div class="container">
            <Gallery :gallery-data="galleryData" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
          </div>
        </div>

<!--        结束-->






      </div>
    </div>
    <!-- End Accordion Area -->

    <Separator/>


  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Separator from '../components/elements/separator/Separator'
import Gallery from "@/components/elements/gallery/Gallery";

export default {
  name: 'AccordionPage',
  components: { Layout, SectionTitle, Separator,Gallery},
  data() {
    return {
      galleryData: [
        {
          id: 1,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p1.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p1.png',
          type: 'image'
        },
        {
          id: 2,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p2.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p2.png',
          type: 'image'
        },
        {
          id: 3,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p3.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p3.png',
          type: 'image'
        },
        {
          id: 4,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p4.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p4.png',
          type: 'image'
        },
        {
          id: 5,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p5.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p5.png',
          type: 'image'
        },
        {
          id: 6,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p6.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p6.png',
          type: 'image'
        },
        {
          id: 7,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p7.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p7.png',
          type: 'image'
        },
        {
          id: 8,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p8.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p8.png',
          type: 'image'
        },
        {
          id: 9,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p9.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/beer/%E5%95%A4%E9%85%92%E8%8A%82/p9.png',
          type: 'image'
        }

      ]
    }
  },
}
</script>
