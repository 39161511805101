<template>
    <Layout>
        <!-- Start Elements Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="优秀师资"
                            title="安连优秀的师资队伍"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData"
                         :key="index">
                        <NewTeam :team-member="teamMember"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator/>



    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Team from '../components/elements/team/Team'
    import NewTeam from "@/components/elements/team/NewTeam";
    import Separator from '../components/elements/separator/Separator'

    export default {
        name: 'TeamPage',
        components: {Separator, Team, SectionTitle, Layout, Breadcrumb,NewTeam},
        data() {
            return {
                teamData: [

                  {
                    image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/%E5%88%98%E9%A1%BC920.jpg',
                    name: '刘老师',
                    designation: '曾任重庆邮电大学移动通讯学院德语教师，山东农业大学国际交流学院双语教研室主任。',
                    location: '西安，陕西',
                    description: '幽默亲和，深入浅出，善于将复杂抽象的理论以浅显易懂而有趣的方式表达出来。主讲课程:  A1-B2等级班    德福考前阅读强化'

                  },
                  {
                    image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/jonas920.jpg',
                    name: 'Jonas老师',
                    designation: '6年教学经验，曾任台湾政治大学教师，柏林国际语言学校德语教师。',
                    location: '西安，陕西',
                    description: '寓教于乐，课堂气氛活跃，善于互动，授课内容有趣丰富，让学生在收获知识的同时收获快乐。主讲课程:  A1-B2等级口语课、德福考前口语强化、DSD备考课程'

                  },
                    {
                        image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/%E5%B0%9A%E5%A4%A9%E6%AC%A3920.jpg',
                        name: '尚老师',
                        designation: '曾任西安外国语大学培训部德语教师、国家汉办专职德语翻译',
                        location: '西安，陕西',
                        description: '轻松幽默，课堂掌控能力强，基础知识结合适当拓展，善于调动大多数学生的学习积极性。擅长语法和词法，特别是写作和听力的提升。主讲课程:  A1-B2等级班、德福强化、口译专业初复试备考。'
                    },
                  {
                    image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/%E6%9D%8E%E6%A0%8B920.jpg',
                    name: '李老师',
                    designation: '曾常年从事德国，奥地利，瑞士等欧洲国家民间交流访问工作；长期从事欧洲国家跨文化交际领域；德语旅游业从业者专业评委考官',
                    location: '西安，陕西',
                    description: '擅长德语入门以及初级阶段教学，教学经验丰富，上课风格幽默风趣，个人魅力突出；学生好评率多次排名第一主讲课程:  德语入门，进阶课程、歌德考前强化备考'

                  },
                  {
                    image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/%E5%88%98%E4%BA%AE920.jpg',
                    name: '刘老师',
                    designation: '曾于德国波鸿鲁尔大学语言学院担任讲师；长期从事德语语言与欧洲文化历史研究。',
                    location: '西安，陕西',
                    description: '有趣、灵活、不教条，注重以简化繁与知识点间的融汇贯通。主讲课程:  德语入门与进阶课程、德语语法、德国及欧洲文化'

                  },
                  {
                    image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/%E8%B0%A2%E5%AE%81920.jpg',
                    name: '谢老师',
                    designation: '曾在奥地利萨尔茨堡大学任教，并在国内高校任教多年',
                    location: '西安，陕西',
                    description: '擅长中级班教学，教学经验丰富，德语的教学过程中，既重视听说又不乏传授语法知识。同时注重基础知识，以培训学生较全面扎实的基本功为主主讲课程:  德语中级课程'

                  }



                ]

            }
        }
    }
</script>
