<template>
    <div class="about-area about-style-4 rn-section-gap">
        <div class="container">
            <div class="row row--40 align-items-center">
                <div class="col-lg-6">
                    <Video :video-data="videoData" column="col-12"/>
                </div>
                <div class="col-lg-6 mt_md--40 mt_sm--40">
                    <div class="content">
                        <div class="inner">
                            <h3 class="title">安连德语  只为 <strong>德语</strong></h3>
                            <ul class="feature-list">
                                <li>
                                    <Icon name="check" size="20" icon-class="icon"/>
                                    <div class="title-wrapper">
                                        <h4 class="title">安连专注于德语培训，有幸与你同行。</h4>
                                        <p class="text">
                                          安连德语（Allianz Deutsch）在世界范围内促进德语语言教学并从事国际文化合作，
                                          有着丰富的教学经验及其专业的、有针对性的外语培训组织实施能力， 越来越多地接受中国各级政府和企业的委托，
                                          组织在德国和欧洲的各类专业培训和考察。西安雁塔长安外语培训中心是在教育局注册成立的正规培训机构。
                                          中心自成立以来一直为西安多所高校的德国 项目提供着高质量的德语培训和组织各种跨文化活动。
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <Icon name="check" size="20" icon-class="icon"/>
                                    <div class="title-wrapper">
                                        <h4 class="title">德语学习，唯有专一与执着，才是通向明日坦途的捷径。</h4>
                                        <p class="text">
                                          安连德语（Allianz Deutsch）与中国国家外专局长安培训中心（西安）合作开展日常德语、专业德语、职业德语培训。
                                          安连德语已在西安为多所大学的德国项目提供高质量的德语培训服务，可为学生推荐参加德国大学合作的团审项目。
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <div class="about-btn mt--30">
                                <router-link class="btn-default" to="/contact">联系我们</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Video from "../video/Video";

    export default {
        name: 'AboutFour',
        components: {Video, Icon},
        props: {
            image: {}
        },
        data() {
            return {
                videoData: [
                    {
                        thumb: this.image,
                        src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/2018.10.14%E5%AE%89%E8%BF%9E%E5%BE%B7%E8%AF%AD%E5%8D%81%E5%91%A8%E5%B9%B4%E5%BA%86%E5%85%B8%E8%8A%B1%E7%B5%AE%E7%89%88.mp4',
                    }
                ]
            }
        }
    }
</script>
