<template>
    <Layout>
        <!-- Start Accordion Area -->
        <div class="rn-accordion-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <SectionTitle
                            class="mb--30"
                            text-align="center"
                            subtitle="西安安连德语"
                            title="学生二十问"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row mt--35 row--20">
                    <div class="col-lg-10 offset-lg-1">
                        <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->

        <Separator/>

        
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Separator from '../components/elements/separator/Separator'
    import Accordion from '../components/elements/accordion/Accordion'

    export default {
        name: 'AccordionPage',
        components: {Accordion, Layout, Breadcrumb, SectionTitle, Separator},
        data() {
            return {
                accordion:[]
            }
        },
    }
</script>