<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon">
                    <Icon :name="service.icon" :class="`size-${iconSize}`"/>
                </div>
                <div class="content">
                    <h4 class="title w-600">
                        <router-link to="/###" v-html="service.title"/>
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="service.description"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'ServiceOne',
        components: {Icon},
        props: {
            serviceStyle: {
                type: String
            },
            iconSize: {
                type: String|Number,
                default: 62
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        icon: 'activity',
                        title: '考级德语',
                        description: '针对不同等级要求与内容，精心研发成功专业而科学的考点强化内容。疑难考点一点就通'
                    },
                    {
                        icon: 'cast',
                        title: '留学德语',
                        description: '根据学员情况量身定制个性化留学方案。从学员的院校专业选择，申请材料准备'
                    },
                    {
                        icon: 'map',
                        title: '兴趣德语',
                        description: '德语兴趣课堂，精品小班，轻松实现德语入门'
                    },
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>