<template>
  <Layout>
    <div class="rwt-team-area rn-section-gap">
      <div class="wrapper plr--65">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                subtitle="优秀学员"
                title="德福高分"
                description="一届一届学生, <br /> 都取得了优异成绩"
                data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--15">
          <div class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
               v-for="(teamMember, index) in teamData"
               :key="index">
            <Team :team-member="teamMember" :team-style="1"/>
          </div>
        </div>
      </div>
    </div>


    <Separator/>



  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Team from '../components/elements/team/Team'
import Separator from '../components/elements/separator/Separator'

export default {
  name: 'TeamPage',
  components: {Separator, Team, SectionTitle, Layout, Breadcrumb},
  data() {
    return {
      teamData: [
        {
          image: '刘同学',
          name: '李同学',
          designation: '德福分数：18分',
          location: '西安，陕西',
          description: ''
        },
        {
          image: '郝同学',
          name: '郝同学',
          designation: '德福分数：17分',
          location: '西安，陕西',
          description: ''

        },
        {
          image: '梁同学',
          name: '梁同学',
          designation: '德福分数：18分',
          location: '西安，陕西',
          description: ''

        },
        {
          image: '任同学',
          name: '任同学',
          designation: '德福分数:19分',
          location: '西安，陕西',
          description: ''

        },
        {
          image: '薛同学',
          name: '薛同学',
          designation: '德福分数：17分',
          location: '西安，陕西',
          description: ''

        },
        {
          image: '张同学',
          name: '张同学',
          designation: '德福分数:19分',
          location: '西安，陕西',
          description: ''

        }


      ]

    }
  }
}
</script>
