<template>
  <Layout>
    <!-- Start Accordion Area -->
    <div class="rn-accordion-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <SectionTitle
                class="mb--30"
                text-align="center"
                subtitle="安连德语"
                title="安连复活节"
                description=" 每年的3月中旬至4月初，万物复苏，正是踏青的好时节，而此时复活节也悄然到来。

在德国，画彩蛋，找彩蛋游戏和复活节兔子是这里过复活节不可或缺的部分。在复活节星期日孩子们会提着小篮子穿梭于花园里，去寻找被藏起来的彩蛋和糖果。复活节的星期一，如果天气给力，全家人也会一起出游。

每年4月，安连德语也都会组织开办盛大的复活节，邀请我们所有的德国教师，友人，当然，不能少了我们的主角，学习德语的学生。那藏在我们身边的画着彩妆的鸡蛋，兔形的德国巧克力，让我们一起领略这个春天的风采，踏着希望，追逐梦想。 "
                data-aos="fade-up"
                data-aos-delay="60"
            />
          </div>
        </div>

<br>
<br>


        <!--        开始-->

        <div class="rwt-gallery-area rn-section-gapBottom">
          <div class="container">
            <Gallery :gallery-data="galleryData" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
          </div>
        </div>

        <!--        结束-->






      </div>
    </div>
    <!-- End Accordion Area -->

    <Separator/>


  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Separator from '../components/elements/separator/Separator'
import Gallery from "@/components/elements/gallery/Gallery";

export default {
  name: 'AccordionPage',
  components: { Layout, SectionTitle, Separator,Gallery},
  data() {
    return {
      galleryData: [
        {
          id: 1,
          thumb:'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f1.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f1.png',
          type: 'image'
        },
        {
          id: 2,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f2.png',
          src:'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f2.png',
          type: 'image'
        },
        {
          id: 3,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f3.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f3.png',
          type: 'image'
        },
        {
          id: 4,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f4.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f4.png',
          type: 'image'
        },
        {
          id: 5,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f5.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f5.png',
          type: 'image'
        },
        {
          id: 6,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f6.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f6.png',
          type: 'image'
        },
        {
          id: 7,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f7.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f7.png',
          type: 'image'
        },
        {
          id: 8,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f8.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f8.png',
          type: 'image'
        },
        {
          id: 9,
          thumb: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f9.png',
          src: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/easterday/%E5%A4%8D%E6%B4%BB%E8%8A%82/f9.png',
          type: 'image'
        }

      ]
    }
  },
}
</script>
