<template>
    <Layout header-class="header-left-aline"  :footer-style="1">


        <!-- Start Service Area -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title">这世界辽阔<br></h3>
                                <h3 class="title">我们只专注于<br></h3>
                                <h3 class="title">德 语 </h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            安连德语（Allianz Deutsch）在世界范围内促进德语语言教学并从事国际文化合作，有着丰富的教学经验及其专业的、有针对性的外语培训组织实施能力，
                            越来越多地接受中国各级政府和企业的委托，组织在德国和欧洲的各类专业培训和考察。西安雁塔长安外语培训中心是在教育局注册成立的正规培训机构。
                            中心自成立以来一直为西安多所高校的德国
                            项目提供着高质量的德语培训和组织各种跨文化活动。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Service Area -->

        <!-- Start Brand Area -->
        <!-- <div class="rwt-brand-area pb--60 pt--30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Brand :brand-list="brandList" :brand-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Brand Area -->

        <Separator/>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="安连优势"
                            title="用心服务每一位学生"
                            description="安连德语（Allianz Deutsch）与中国国家外专局长安培训中心（西安）合作开展日常德语、专业德语、职业德语培训。安连德语已在西安为多所大学的德国项目提供高质量的德语培训服务，可为学生推荐参加德国大学合作的团审项目。"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </div>
        <!-- End Service Area -->

        <div class="rwt-timeline-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div>

        <Separator/>

        <AboutFour :image="require(`@/assets/images/anlian/安连德语.png`)"/>

        <!-- Start Elements Area -->
        <div class="rwt-counterup-area pb--100">
            <div class="container mt_dec--30">
                <Counter
                    :counter-style="5"
                    text-align="center"
                    :counter-data="counterData"
                    column="col-lg-3 col-md-6 col-sm-6 col-12"
                />
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator/>

        <!-- Start Elements Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="优秀师资"
                            title="安连强大的师资队伍"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
                         v-for="(teamMember, index) in teamData"
                         :key="index">
                        <NewTeam :team-member="teamMember" :team-style="4"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'
    import NewTeam from "@/components/elements/team/NewTeam";

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout,NewTeam},
        computed:{
        aboutBgimage(){
          return "background:url("+this.aboutBgimag+")"+"center center /cover no-repeat"
        }
      },

      data() {
            return {


              aboutBgimag: "https://pic1.zhimg.com/v2-c4f561cf0c23beced24b56f7fcac997c_r.jpg?source=1940ef5c",
                timelineData: [
                    {
                        id: '1',
                        title: '安连成立',
                        description: '2008年安连成立',
                    },
                    {
                        id: '2',
                        title: '西电成立校内班',
                        description: '成立西安电子科技大学校内班，校企合作',
                    },
                    {
                        id: '3',
                        title: '长安大学校区成立',
                        description: '长安校区成立，专注德语培训和留学',
                    },
                    {
                        id: '4',
                        title: '未来憧憬',
                        description: '立足西安，以优质的教学服务每一位学生',
                    },
                ],
                counterData: [
                    {
                        number: 22,
                        title: '成立时间',
                    },
                    {
                        number: 6000,
                        title: '成功留学',
                    },
                    {
                        number: 20,
                        title: '丰富的种类课程',
                    },
                    {
                        number: 9999,
                        title: '我们的服务',
                    }
                ],
                teamData: [
                    {
                        image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/%E5%88%98%E9%A1%BC.jpg',
                        name: '刘老师',
                        designation: '就职于青岛大学德语系，任讲师及青岛歌德语言中心中方主任，共有6年外语教学经验。德语专业八级，英语专业八级，旅德10年，有丰富的德国求学，生活和工作经验。',
                        location: '西安，陕西',
                        description: '温柔亲和，兼备德式严谨；课文精讲精练；重视发音与口语交际训练；善用多媒体把德国社会的多种面向带到课堂上；旅德亲身经历与教学内容融合，全方位为学员的学习之旅答疑解惑。主讲课程:德语中高级课程，德福听力考前强化'
                    },
                    {
                        image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/jonas.jpg',
                        name: 'Jonas老师',
                        designation: '6年教学经验，曾任台湾政治大学教师，柏林国际语言学校德语教师。',
                        location: '西安，陕西',
                        description: '寓教于乐，课堂气氛活跃，善于互动，授课内容有趣丰富，让学生在收获知识的同时收获快乐。主讲课程:  A1-B2等级口语课、德福考前口语强化'
                    },
                    {
                        image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/%E6%9D%8E%E6%A0%8B.jpg',
                        name: '李老师',
                        designation: '曾常年从事德国，奥地利，瑞士等欧洲国家民间交流访问工作；长期从事欧洲国家跨文化交际领域；德语旅游业从业者专业评委考官',
                        location: '西安，陕西',
                        description: '擅长德语入门以及初级阶段教学，教学经验丰富，上课风格幽默风趣，个人魅力突出；学生好评率多次排名第一主讲课程:  德语入门，进阶课程、歌德考前强化备考'
                    },
                    {
                        image: 'https://anliandy.oss-cn-hangzhou.aliyuncs.com/teacher/%E5%88%98%E4%BA%AE.jpg',
                        name: '刘老师',
                        designation: '曾于德国波鸿鲁尔大学语言学院担任讲师；长期从事德语语言与欧洲文化历史研究。',
                        location: '西安，陕西',
                        description: '有趣、灵活、不教条，注重以简化繁与知识点间的融汇贯通。主讲课程:  德语入门与进阶课程、德语语法、德国及欧洲文化'
                    },
                ]
            }
        }
    }
</script>
